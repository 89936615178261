import Banner from "../components/Banner.js";
import Layout from "../components/layout.js";
import { Spacer, Text } from "@nextui-org/react";

const Home = () => {
  return (
    <Layout>
      <Text h1>Editorland</Text>
      <Text b>Submission management for everyone.</Text>
      <Spacer y={1} />
      <Banner type="warning">
        Sorry, there's nothing here. Please check the URL.
      </Banner>
    </Layout>
  );
};

export default Home;
